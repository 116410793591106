import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GlobalTop from "../components/globalTop"
import CareerForm from "../components/careerForm"

const Career = ({ pageContext }) => {
  const fullSchema = JSON.parse(pageContext.data.seo.schema.raw)
  const title = pageContext.data.title

  return (
    <Layout>
      <SEO title={title} />
      <GlobalTop title={title} kariera />
      <section className="career-content">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-5">
              <h2 className="section--header">{title}</h2>
              <div className="career-content__description">
                {pageContext.data.acfCareer.content?.map((item, index) => (
                  <div className="career-content__description-row" key={index}>
                    {item.title && <h4>{item.title}</h4>}
                    <div
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-6 offset-lg-1">
              <div className="career-content__thumbnail">
                <div
                  className="career-content__thumbnail-inner"
                  style={{
                    backgroundImage: `url(${pageContext.data.acfCareer.thumbnail.sourceUrl})`,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <CareerForm position={title} />

      {fullSchema && (
        <script type="application/ld+json">
          {JSON.stringify({ ...fullSchema }, null, 2)}
        </script>
      )}
    </Layout>
  )
}

export default Career
