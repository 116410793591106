import React, { useState } from "react"

const InputFile = ({ value, accept = "", maxFileSize = 5, onChange }) => {
  const [error, setError] = useState(null)

  const handleOnChange = event => {
    if (onChange && typeof onChange === "function") {
      const file = event.target.files[0]
      const fileType = file.name.substring(
        file.name.lastIndexOf("."),
        file.name.length
      )
      const isValid =
        accept && accept !== ""
          ? accept.split(",").some(element => element === fileType)
          : true
      const isSizeCorrect = file.size <= maxFileSize * 1000000

      if (isValid && isSizeCorrect) {
        onChange(file)
        setError(null)
      } else {
        if (!isSizeCorrect) {
          setError(
            `Plik jest za duży. Waga pliku przekracza ${maxFileSize} MB.`
          )
        } else {
          setError("Wybrany plik nie jest obsługiwany.")
        }
      }
    }
  }

  const handleClear = () => {
    if (onChange && typeof onChange === "function") {
      onChange(null)
      setError(null)
    }
  }

  return (
    <label className="file-wrapper">
      <input type="file" accept={accept} onChange={handleOnChange} />
      <div className="file-wrapper__icon" />
      {value ? (
        <div className="file-wrapper__file">
          Plik: {value && value.name}{" "}
          <button
            className="file-wrapper__clear"
            aria-label="Usuń plik"
            onClick={handleClear}
          />
        </div>
      ) : (
        <div className="file-wrapper__placeholder">
          <p>Dodaj plik ze swoim CV*</p>
          <p>
            Wielkość pliku nie może przekroczyć {maxFileSize} MB. Dopuszczalne
            formaty to PDF, DOC, DOCX.
          </p>
        </div>
      )}
      {error && <div className="file-wrapper__error">{error}</div>}
    </label>
  )
}

export default InputFile
