import React, { useState } from "react"
import { navigate } from "gatsby"
import axios from "axios"

import Select from "./select"
import InputFile from "./inputFile"

const initialData = {
  position: "",
  otherPosition: "",
  nameSurname: "",
  email: "",
  phone: "",
  file: null,
  message: "",
  legal: false,
}

const selectManualEntryValue = "Moja specjalizacja nie dotyczy powyższych"

const CareerForm = ({ positions, position }) => {
  const [send, setSend] = useState(false)
  const [data, setData] = useState(
    position ? { ...initialData, position: position } : initialData
  )

  const resetForm = () =>
    setData(position ? { ...initialData, position: position } : initialData)

  const handleSubmit = e => {
    if (!send) {
      e.preventDefault()
      setSend(true)

      let formData = new FormData()

      Object.entries(data).forEach(([key, value]) => {
        formData.set(key, value)
      })

      axios({
        method: "post",
        url:
          "https://konfederacka.zenx.pl/wp-json/contact-form-7/v1/contact-forms/3552/feedback/",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(_ => {
          resetForm()
          navigate("/kariera-potwierdzenie/")
        })
        .catch(() => {
          console.error("Submit error")
        })
    }
  }

  const isFormValid = validateForm(data)

  return (
    <section className="career-form">
      <div className="container">
        <h2 className="section--header">
          <strong>Jesteś zainteresowany pracą z nami?</strong>
          Wypełnij formularz i aplikuj na stanowisko
        </h2>
        <div className="form">
          {position ? (
            <div className="input-blank">{position}</div>
          ) : (
            <Select
              options={positions}
              selected={data.position}
              input={selectManualEntryValue}
              onClick={value =>
                setData(prevData => ({
                  ...prevData,
                  position: value,
                  otherPosition: "",
                }))
              }
            />
          )}
          {data.position === selectManualEntryValue && (
            <div className="input-wrapper">
              <input
                type="text"
                placeholder="Podaj specjalizację*"
                value={data.otherPosition}
                onChange={e => {
                  const value = e.target.value

                  setData(prevData => ({ ...prevData, otherPosition: value }))
                }}
              />
            </div>
          )}
          <div className="input-wrapper">
            <input
              type="text"
              placeholder="Imię i nazwisko*"
              value={data.nameSurname}
              onChange={e => {
                const value = e.target.value

                setData(prevData => ({ ...prevData, nameSurname: value }))
              }}
            />
          </div>
          <div className="input-wrapper">
            <input
              type="email"
              placeholder="Adres e-mail*"
              value={data.email}
              onChange={e => {
                const value = e.target.value

                setData(prevData => ({
                  ...prevData,
                  email: value,
                }))
              }}
            />
          </div>
          <div className="input-wrapper">
            <input
              type="text"
              placeholder="Numer telefonu*"
              value={data.phone}
              onChange={e => {
                const value = e.target.value

                setData(prevData => ({
                  ...prevData,
                  phone: value,
                }))
              }}
            />
          </div>
          <InputFile
            value={data.file}
            accept=".pdf,.doc,.docx"
            onChange={value => {
              setData(prevData => ({ ...prevData, file: value }))
            }}
          />
          <div className="input-wrapper">
            <textarea
              rows="6"
              placeholder="Wiadomość*"
              value={data.message}
              onChange={e => {
                const value = e.target.value

                setData(prevData => ({
                  ...prevData,
                  message: value,
                }))
              }}
            />
          </div>
          <p className="field-required">* Pola obowiązkowe</p>
          <label className="checkbox-wrapper">
            <input
              type="checkbox"
              checked={data.legal}
              onChange={() =>
                setData(prevData => ({
                  ...prevData,
                  legal: !prevData.legal,
                }))
              }
              required
            />
            <span>
              *Wyrażam dobrowolną zgodę na przetwarzanie moich danych osobowych
              przez Gabinet podologiczny Agnieszka Kaszuba ul. Konfederacka 11 w
              Krakowie (Administrator danych), w celu marketingu bezpośredniego
              dotyczącego własnych produktów i usług. Obowiązek informacyjny
              zgodnie z art. 13 ogólnego rozporządzenia o ochronie danych
              osobowych (RODO) z dnia 27 kwietnia 2016 r. (Dz. Urz. UE L 119 z
              04.05.2016): informacje o przetwarzaniu przez nas danych, jak
              również o prawach osób, których dane dotyczą znajdują się w naszej{" "}
              <a href="/polityka-prywatnosci/" target="_blank">
                polityce prywatności
              </a>
              .
            </span>
          </label>
          <div className="submit-wrapper">
            <button
              className="btn-custom btn-custom--purple"
              type="submit"
              disabled={!isFormValid || send}
              onClick={handleSubmit}
            >
              {send === true ? "Wysyłanie..." : "Wyślij"}
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

const validateForm = data => {
  if (!data) return false

  let isValid = false
  let loopBreak = false
  let isPositionOnList = true

  const setIsValid = () => {
    isValid = true
  }

  const setIsInvalid = () => {
    loopBreak = true
    isValid = false
  }

  Object.entries(data).forEach(([key, value]) => {
    if (!loopBreak) {
      if (key === "email") {
        if (new RegExp("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$").test(value)) {
          setIsValid()
        } else {
          setIsInvalid()
        }
      } else if (key === "phone") {
        if (
          new RegExp("^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$").test(
            value
          ) &&
          value.length >= 9
        ) {
          setIsValid()
        } else {
          setIsInvalid()
        }
      } else if (key === "legal" || key === "file") {
        if (value) {
          setIsValid()
        } else {
          setIsInvalid()
        }
      } else if (key === "otherPosition") {
        if (!isPositionOnList) {
          if (value?.length >= 3) {
            setIsValid()
          } else {
            setIsInvalid()
          }
        } else {
          setIsValid()
        }
      } else {
        if (value?.length >= 3) {
          if (key === "position" && value === selectManualEntryValue) {
            isPositionOnList = false
          }

          setIsValid()
        } else {
          setIsInvalid()
        }
      }
    }
  })

  return isValid
}

export default CareerForm
