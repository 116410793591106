import React, { useState } from "react"

const Select = ({ options, selected, input, onClick }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleOptionClick = value => {
    if (onClick && typeof onClick === "function") {
      onClick(value)
    }

    setIsOpen(false)
  }

  const optionsParsed = options
    ? [...options, input].filter(item => item)
    : undefined

  return (
    <div className={`select ${isOpen ? "open" : ""}`}>
      <button
        className="select__toggle"
        onClick={() => setIsOpen(prevOpen => !prevOpen)}
      >
        {options
          ? selected
            ? selected
            : "Podaj specjalizację*"
          : "Brak danych"}
      </button>
      {optionsParsed && (
        <div className="select__dropdown">
          <ul className="select__list">
            {optionsParsed.map(option => {
              const isActive = selected === option

              return (
                <li key={option}>
                  <button
                    className={`select__list-option ${
                      isActive ? "active" : ""
                    }`}
                    onClick={() => handleOptionClick(isActive ? "" : option)}
                  >
                    {option}
                  </button>
                </li>
              )
            })}
          </ul>
        </div>
      )}
    </div>
  )
}

export default Select
